body, html {
    font-size: 14px;
}

h2 {
    font-size: 1.6em !important;
    margin-bottom: 0.7em !important;
}

h3 {
    font-size: 1.4em !important;
}

.pagepanel {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
}

.btn-light {
    background-color: #e2e6ea !important;
    border-color: #e2e6ea !important;
}

.btn-light:hover {
    background-color: #dadee3 !important;
    border-color: #dadee3 !important;
}
.main {
    padding: 1rem;
    padding-top: 68px;
}

.section-header {
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    font-size: 1.2em;
    border-bottom: 0px !important;
    color: #ffffff;
    background-color: #7da7d4;
}

.sidebar {
    padding: 0px;
}

.sidebar-sticky {
    position: relative;
    _height: calc(100vh);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem 0;
    padding-top: 60px;
}

.nav-link {
}

.user-badge {
    padding: 0.2rem 1rem;
}

.clickable {
    cursor: pointer;
}

.help {
    color: #cc3300;
}

.table thead th {
    border-bottom: none !important;
    border-top: none !important;
    font-weight: normal;
    font-size: 0.8em;
    text-transform: uppercase;

}

.fieldname {
    font-weight: normal;
    font-size: 0.8em;
    text-transform: uppercase;
    vertical-align: middle;
}

.lighter {
    color: #cccccc;
}

.table-nonfluid {
    width: auto !important;
}

.validationerrors {
    color: #cc3300;
}

.align-bottom .form-group {
    margin-top: auto;
}

.card-file {
    background-color: #fcfcfc !important;
    border: 0px !important;
}

.card-file:hover {
    background-color: #eeeeee !important;
}

.table-hover tbody tr:hover {
    background-color: #fafafa !important;
}

.nowrap {
    white-space: nowrap;
}

.smaller {
    font-size: 0.8em;
}

.muchsmaller {
    font-size: 0.6em;
}

.table-spaced th {
    padding-right: 20px !important;
}

.table-spaced td {
    padding-right: 20px !important;
}

.stationmap {
    width: 100%;
    height: 400px;
}

.submissionmap {
    width: 100%;
    height: 400px;
}

textarea {
    resize: none !important;
    height: 150px !important;
}

.required {
    color: #cc3300;
}

.required-conditional {
    color: #cccccc;
}

ol, ul {
    margin-left: 2em;
}

ol li {
    margin-bottom: 10px;
}

em {
    font-weight: bold;
}

#wrapper {
    min-height: 100vh;
}

footer {
    height: 100px;
    background-color: #333333;
    padding-top: 10px;
}

#ioclogo {
    height: 90px;
    opacity: 0.5;
}

div[role=tabpanel] {
    padding-top: 1em;
    padding-left: 1em;
}

.icon-cc {
    height: 20px;
}

table tbody td {
    vertical-align: top;
}

.badge-light {
    background-color: #e2e6ea !important;
}

.loading {
    text-align: center;
    color: #cccccc;
}

.Collapsible__trigger {
    cursor: pointer;
    font-weight: bold;
}

.Collapsible {
    margin-top: 5px;
}

.Collapsible__contentOuter {
    margin-top: 5px;
    -webkit-transition: height 200ms linear 0s !important;
    transition: height 200ms linear 0s !important;
}
.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
          animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
          animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
          animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
          animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.9;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: #373737;
  border-radius: 6px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #373737;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #373737;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #373737;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #373737;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

