body, html {
    font-size: 14px;
}

h2 {
    font-size: 1.6em !important;
    margin-bottom: 0.7em !important;
}

h3 {
    font-size: 1.4em !important;
}

.pagepanel {
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
}

.btn-light {
    background-color: #e2e6ea !important;
    border-color: #e2e6ea !important;
}

.btn-light:hover {
    background-color: #dadee3 !important;
    border-color: #dadee3 !important;
}
.main {
    padding: 1rem;
    padding-top: 68px;
}

.section-header {
    padding: 0.75rem 1.25rem;
    cursor: pointer;
    font-size: 1.2em;
    border-bottom: 0px !important;
    color: #ffffff;
    background-color: #7da7d4;
}

.sidebar {
    padding: 0px;
}

.sidebar-sticky {
    position: relative;
    _height: calc(100vh);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1rem 0;
    padding-top: 60px;
}

.nav-link {
}

.user-badge {
    padding: 0.2rem 1rem;
}

.clickable {
    cursor: pointer;
}

.help {
    color: #cc3300;
}

.table thead th {
    border-bottom: none !important;
    border-top: none !important;
    font-weight: normal;
    font-size: 0.8em;
    text-transform: uppercase;

}

.fieldname {
    font-weight: normal;
    font-size: 0.8em;
    text-transform: uppercase;
    vertical-align: middle;
}

.lighter {
    color: #cccccc;
}

.table-nonfluid {
    width: auto !important;
}

.validationerrors {
    color: #cc3300;
}

.align-bottom .form-group {
    margin-top: auto;
}

.card-file {
    background-color: #fcfcfc !important;
    border: 0px !important;
}

.card-file:hover {
    background-color: #eeeeee !important;
}

.table-hover tbody tr:hover {
    background-color: #fafafa !important;
}

.nowrap {
    white-space: nowrap;
}

.smaller {
    font-size: 0.8em;
}

.muchsmaller {
    font-size: 0.6em;
}

.table-spaced th {
    padding-right: 20px !important;
}

.table-spaced td {
    padding-right: 20px !important;
}

.stationmap {
    width: 100%;
    height: 400px;
}

.submissionmap {
    width: 100%;
    height: 400px;
}

textarea {
    resize: none !important;
    height: 150px !important;
}

.required {
    color: #cc3300;
}

.required-conditional {
    color: #cccccc;
}

ol, ul {
    margin-left: 2em;
}

ol li {
    margin-bottom: 10px;
}

em {
    font-weight: bold;
}

#wrapper {
    min-height: 100vh;
}

footer {
    height: 100px;
    background-color: #333333;
    padding-top: 10px;
}

#ioclogo {
    height: 90px;
    opacity: 0.5;
}

div[role=tabpanel] {
    padding-top: 1em;
    padding-left: 1em;
}

.icon-cc {
    height: 20px;
}

table tbody td {
    vertical-align: top;
}

.badge-light {
    background-color: #e2e6ea !important;
}

.loading {
    text-align: center;
    color: #cccccc;
}

.Collapsible__trigger {
    cursor: pointer;
    font-weight: bold;
}

.Collapsible {
    margin-top: 5px;
}

.Collapsible__contentOuter {
    margin-top: 5px;
    transition: height 200ms linear 0s !important;
}